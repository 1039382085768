<template>
      <!--Content start-->
    <main id="content">
      <div class="container">
        <div class="not-found py-5">
          <div class="row">
            <div class="col-12 col-sm-10 col-md-8 mx-auto">
              <div class="mb-4 text-center">
                <h1 class="display-3 display-1-lg fw-bold">404</h1>
                <hr class="hr-after mx-auto">
              </div>
              <div class="text-center">
                <h3 class="h2-md arabic-kufi">لا يمكن العثور علي هذة الصفحة !</h3>
              </div>
              <div class="post-content">
                <!-- recent post -->
                <div class="widget">
                  <h2 class="widgettitle arabic-kufi ">اخر الاخبار</h2>
                  <ul>
                    <li v-for="publish in lastnews" :key="publish.id">
                      <a @click="getFeed(publish)" class="arabic-kufi pointer">{{ publish.post_title.substring(0,50) }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!--End Content-->
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { ref } from '@vue/reactivity';
import { useCookie } from 'vue-cookie-next'
export default {
    async setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie()
      const lastnews = ref([]);
      try {
        await HTTP.get(`getPost.php?getPost&LIMIT=8&OFFSET=0`).then((res) => {
          lastnews.value = res.data.getPost; 
        });
      } catch (err) {
        console.log(err);
      }
      const getFeed = (publish) => {
          cookie.setCookie('article_id', publish.id)
          if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
              router.push({ name: "ReadArticle", params: { id: publish.id } });
          } else {
              cookie.setCookie('id', publish.id);
              router.push({ name: "Login" });
          }
        };
      // const getFeed = (publish) => {
      //   if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
      //     router.push({ name: "ReadArticle", params: { id: publish.id } });
      //   } else {
      //     router.push({ name: "NewSub" });
      //   }
      // };
        return {
           lastnews,
           getFeed
        };
   },
}
</script>

<style>
.arabic-kufi {
  font-family: 'Zain_Regular', serif;
}
.pointer {
  cursor: pointer;
}
</style>